import Vue from 'vue';
import App from './App.vue';
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// Add a response interceptor
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
