import IntersectionObserverPolyfill from 'intersection-observer';

export default {
  abstract: true,
  props: {
    threshold: {
      type: Array
    }
  },

  render() {
    try {
      return this.$slots.default[0];
    } catch (e) {
      throw new Error('IntersectionObserver.vue может отрисовать только один дочерний компонент.');
    }
  },

  mounted () {
    const options = {threshold: this.threshold || 0};
    const callback = (entries) => {
      this.$emit(entries[0].isIntersecting ? 'intersect-enter' : 'intersect-leave', [entries[0]]);
    };
    if (window.IntersectionObserver) {
      this.observer = new window.IntersectionObserver(callback, options);
    } else {
      // old browser. load polyfill
      this.observer = new IntersectionObserverPolyfill(callback, options);
    }

    this.$nextTick(() => {
      this.observer.observe(this.$slots.default[0].elm);
    });
  },

  destroyed() {
    this.observer.disconnect();
  }
};
