<template>
  <div id="app" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <img alt="news 24/7" src="/news24.webp" title="news 24/7"/>
        </div>
      </div>
      <div v-for="(offer, index) in offers" :key="offer.position"
           class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 mb-4">
        <IntersectionObserver :threshold="[0, 0.5, 1]" @intersect-enter="handleEnter(offer, index)">
          <a :href="offer.url || ''"
             class="offer d-flex flex-column"
             rel="nofollow noopener"
             target="_blank"
             v-on:click="sendClick(offer)">
            <div :style="{backgroundImage: offer.show_image ? `url('${offer.cover_url}')` : `url('/1x1.png')`}"
                 class="img-wrapper">
            </div>
            <p>{{ offer.title }}</p>

            <span class="views">{{ offer.f_views }}</span>
            <span class="likes">{{ offer.f_likes }}</span>
          </a>
        </IntersectionObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import IntersectionObserver from './components/IntersectionObserver.vue';
import * as Sentry from '@sentry/vue';

export default {
  name: 'App',
  components: {IntersectionObserver},
  data() {
    return {
      showcase_id: undefined,
      stream_id: undefined,
      country_code: undefined,
      offers: [],
      offerImages: {},
      wait_for_all_views: undefined,
      positions_to_send: new Set(),
      sent_positions: new Set(),
      parentParams: {},
      utmCookiePrefix: 'spt_showcase__'
    }
  },
  methods: {
    async sendClick(offer) {
      const params = {...offer};
      const countryCode = this.countryCode;
      if (countryCode) {
        params.country_code = countryCode;
      }
      Sentry.addBreadcrumb({
        category: "clicks",
        message: JSON.stringify(params),
        level: Sentry.Severity.Info,
      });
      try {
        await axios.post('clicks', params)
      } catch (e) {
        this.handleError(e);
      }
    },
    async sendView() {
      if (this.wait_for_all_views) {
        clearTimeout(this.wait_for_all_views);
        this.wait_for_all_views = undefined;
      }
      const indexes = new Set(this.positions_to_send);
      this.positions_to_send.clear();
      const events = [];
      const countryCode = this.countryCode;
      const additionalParams = {};
      if (countryCode) {
        additionalParams.country_code = countryCode;
      }
      for (const index of indexes) {
        if (this.sent_positions.has(index) || !this.offers[index]) {
          continue;
        }
        this.sent_positions.add(index);
        events.push({...this.offers[index], ...additionalParams});
      }
      if (!events.length) {
        return;
      }

      try {
        await axios.post('views', {events})
      } catch (e) {
        this.handleError(e);
      }
    },
    async load() {
      if (!this.showcase_id || !this.stream_id) {
        window.close();
        return;
      }

      const response = await axios.get(`auctions/${this.showcase_id}/${this.stream_id}`);
      for (const el of response.data) {
        if (!el.debug) {
          break; // no debug data
        }
        console.debug('position:' + el.position, el.debug);
      }
      const parentParams = this.parentParams;
      this.offers = response.data.map(
        function (offer) {
          for (const k in offer) {
            if (typeof offer[k] === 'object') {
              continue; // shouldn't be changed
            }
            offer.show_image = false;
            let value = offer[k];
            if (!/^\[.+]$/.test(value)) {
              continue;
            }
            if (typeof value !== 'string') {
              continue;
            }
            const param = value.replace(/[[\]]/g, '');
            offer[k] = parentParams[param] || 'undefined';
          }

          for (const param in parentParams) {
            if (param.includes('utm_')) {
              offer[param] = parentParams[param];
            }
          }

          if (!offer.url) {
            return offer;
          }
          for (const param in parentParams) {
            const toReplace = '%5B' + param + '%5D';
            if (typeof offer.url.replaceAll === "function") {
              offer.url = offer.url.replaceAll(toReplace, parentParams[param]);
            } else {
              offer.url = offer.url.replace(toReplace, parentParams[param]);
            }
          }

          return offer;
        }
      );
    },
    handleError(e) {
      if (!(['Network Error', 'Request aborted'].includes(e.message))) {
        throw e;
      }
    },
    ipLookUp(times = 1) {
      const self = this;

      // hack to request without cors
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status !== 200) {
            if (times <= 5) {
              self.ipLookUp(times + 1);
            }
            return;
          }
          const response = JSON.parse(this.responseText);
          self.setCountryCode(response.country_code);
        }
      };
      xhr.open('GET', 'https://geoip.global-tidings.com/api/v1/geo', true);
      xhr.send();
    },
    syncCountryCode() {
      try {
        if (localStorage.country_code) {
          this.setCountryCode(localStorage.country_code);
          return;
        }
      } catch (e) {
        // local storage permission denied
      }
      this.ipLookUp();
    },
    setCountryCode(countryCode) {
      this.country_code = countryCode;
      try {
        localStorage.country_code = countryCode;
      } catch (e) {
        // local storage permission denied
      }
    },
    async waitForCountryCode(times = 1) {
      const self = this;

      await new Promise(
        function (resolve) {
          setTimeout(
            async function () {
              if (!this.country_code && times < 3) {
                await self.waitForCountryCode(times + 1);
                resolve();
              }
            },
            500
          );
        }
      );
    },
    async handleEnter(offer, index) {
      offer.show_image = true;
      if (!this.country_code) {
        await this.waitForCountryCode();
      }
      if (this.sent_positions.has(index)) {
        return;
      }
      this.positions_to_send.add(index);
      if (this.wait_for_all_views) {
        clearTimeout(this.wait_for_all_views);
      }
      this.wait_for_all_views = setTimeout(() => this.sendView(), 250);
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=None;Secure";
    }
  },
  watch: {
    countryCode() {
      if (
        !this.country_code ||
        typeof this.country_code !== 'string' ||
        this.country_code === 'null' ||
        this.country_code === 'undefined'
      ) {
        return '';
      }

      return this.country_code.substring(0, 2);
    }
  },
  created() {
    let uri = window.location.href.split('?');
    if (uri.length !== 2) {
      window.close();
      return;
    }

    const vars = uri[1].split('&');
    const result = {};
    vars.forEach(function (v) {
      const tmp = v.split('=');
      if (tmp.length === 2) {
        result[tmp[0]] = tmp[1];
      }
    });

    // save affiliate data for feature requests
    for (const utm of ['utm_team', 'utm_user']) {
      const utmKey = this.utmCookiePrefix + utm;
      let utmValue;
      try {
        utmValue = localStorage[utmKey];
      } catch (e) {
        // no permission
      }
      if (!utmValue) {
        utmValue = this.getCookie(utmKey);
      }
      if (utmValue) {
        result[utm] = utmValue;
      } else if (result[utm]) {
        try {
          localStorage[utmKey] = result[utm];
        } catch (e) {
          // no permission
        }
        this.setCookie(utmKey, result[utm], 1);
      }
    }

    this.parentParams = result;
    this.showcase_id = result.showcase_id;
    this.stream_id = result.stream_id;
    this.load();
    this.syncCountryCode();
  }
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap-grid.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.offer, .offer:active, .offer:focus, .offer:link, .offer:hover {
  display: block;
  height: 100%;
  color: #2c3e50;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  text-align: left;
  border-radius: 5px;

  -webkit-box-shadow: 1px 1px 8px 2px rgba(34, 60, 80, 0.09);
  -moz-box-shadow: 1px 1px 8px 2px rgba(34, 60, 80, 0.09);
  box-shadow: 1px 1px 8px 2px rgba(34, 60, 80, 0.09);

  position: relative;
  padding-bottom: 25px;
}

.offer:hover {
  color: #3163dc;
}

.offer p {
  padding: 15px;
  flex: 1;
}

.offer .img-wrapper {
  padding-top: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .offer .img-wrapper {
    padding-top: 50%;
  }
}

.offer .views, .offer .likes {
  background: url("/views.svg") transparent no-repeat left center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 0 10px 25px;
  color: #2c3e50;
}

.offer .views {
  right: auto;
  left: 10px;
}

.offer .likes {
  background-image: url("/likes.svg");
  background-position: right center;
  padding: 10px 25px 6px 0;
  margin-bottom: 4px;
}

.offer .views:hover, .offer .likes:hover {
  color: #3163dc;
}

.offer .likes:hover {
  background-image: url("/likes_active.svg");
}

.offer .views:hover {
  background-image: url("/views_active.svg");
}
</style>
